export default function parseContent(
  content: any,
  parser?: (content: any) => void
) {
  if (parser) {
    return parser(content);
  }
  if (typeof content === "string") {
    return JSON.parse(content);
  }
  return content;
}

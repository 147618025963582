import React from 'react';
import { GatsbyImage as Image } from 'gatsby-plugin-image';
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'

interface ImageProps {
  src: any;
  alt: string;
  // All other props
  [x:string]: any;
}


const GatsbyImage:React.FC<ImageProps> = ({ src, ...rest }) => {
  return <Image image={src} {...rest} />;
}

export default GatsbyImage;

export const FluidImage = ({
  blok,
  alt,
  backgroundColor,
  quality = 50,
  maxWidth = 2000,
  maxHeight,
  ...rest
}: {
  blok: { filename: string };
  alt?: string;
  backgroundColor?: string;
  quality?: number;
  maxWidth?: number;
  maxHeight?: number,
}) => {
  const fluidProps = getFluidGatsbyImage(blok?.filename, {
    maxWidth,
    quality,
    maxHeight,
    alt,
    backgroundColor,
  });

  return <Img fluid={fluidProps} {...rest} />;
};
import React from 'react';
import { Link } from 'gatsby';
import { MenuItemWrapper, MenuItem } from './navbar.style';

type LinkItem = SBlok & {
  name: string;
  link: SFLink
}

type MenuProps = {
  items: LinkItem[];
  className?: string;
};


const Menu: React.FunctionComponent<MenuProps> = ({
  items,
  className,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ['menu'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <MenuItemWrapper className={addAllClasses.join(' ')} {...props}>
      {items.map((item, index) => (
        <MenuItem key={index}>
          {item.link.url ? (
            <a href={item.link.url}>{item.name}</a>
          ) : (
            <Link to={`/${item.link.cached_url.replace('home', '')}`} activeClassName="active-link">
              {item.name}
            </Link>
          )}
        </MenuItem>
      ))}
    </MenuItemWrapper>
  );
};

export default Menu;

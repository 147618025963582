import React from 'react';
import Sticky from 'react-stickynode';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import ScrollToTop from 'react-scroll-up';
import Navbar from './navbar/navbar';
import Footer from './footer/footer';
import ScrollUpButton from './scroll-up-button/scroll-up-button';
import ResetCss from './reset-css';
import { theme } from '../theme';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {

  const { layout } = useStaticQuery(graphql`
  query FetchLayout {
    layout: allStoryblokEntry(filter: {field_component: {eq: "_layout"}}) {
      nodes {
        id
        content
        name
      }
    }
  }  
  `)

const layoutContent =
  typeof layout?.nodes[0]?.content === "string"
    ? JSON.parse(layout.nodes[0].content)
    : layout.nodes[0].content;


  return (
    <ThemeProvider theme={theme}>
      <>
        <ResetCss />
        <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
          <Navbar menuList={layoutContent.menu_list} />
        </Sticky>

        {children}

        <Footer>
          Copyright &copy; {new Date().getFullYear()}
          <a href="https://handiwoker.com/"> Handiwoker, Inc.</a>
        </Footer>
        <ScrollToTop
          showUnder={300}
          duration={700}
          easing="easeInOutCubic"
          style={{ bottom: 30, right: 20 }}
        >
          <ScrollUpButton />
        </ScrollToTop>
      </>
    </ThemeProvider>
  );
};

export default Layout;

import React, { useReducer, useEffect } from 'react';
import SearchReducer, { initialState } from './search-reducer';
import { useStaticQuery, graphql } from 'gatsby';
import { Scrollbars } from 'react-custom-scrollbars';
import { rebuildIndex } from './search-index-builder-function';
import PostList from '../post-list/post-list';
import {
  SearchWrapper,
  SearchForm,
  SearchResult,
  NoResult,
} from './search.style';
import parseContent from '../../utils/parseContent';
import setPlaceholderColor from '../../utils/setPlaceholderColor';

function Search() {
  const [state, dispatch] = useReducer(SearchReducer, initialState);

  const data = useStaticQuery(graphql`
    query {
     searchPostData: allStoryblokEntry(filter: {field_component: {eq: "blog_post"}}, limit: 10000) {
        nodes {
          full_slug
          name
          tag_list
          content
          published_at(formatString: "MMMM DD, YYYY")
        }
      }
    }
  `);


  const dataset = data.searchPostData.nodes;


  /**
   * handles the input change and perfom a search with js-search
   * in which the results will be added to the state
   */
  const searchData = (e: any) => {
    const { search } = state;
    const queryResult = search.search(e.target.value);
    dispatch({
      type: 'SET_SEARCH_QUERY',
      payload: { searchQuery: e.target.value, searchResults: queryResult },
    });
  };


  const handleSubmit = (e: any) => {
    e.preventDefault();
  };
  useEffect(() => {
    if (dataset.length !== 0) {
      let data: any = [];
      dataset.forEach((node : any) => {
        let formatedData = {
          ...node,
          content: parseContent(node.content),
          description: parseContent(node.content).description,
        };
        data.push(formatedData);
      });

      dispatch({ type: 'SET_DATA', payload: data });
      const dataToSearch = rebuildIndex(data);
      if (dataToSearch) {
        dispatch({
          type: 'SET_SEARCH',
          payload: dataToSearch,
        });
      }
    }
  }, [dataset]);

  const { searchResults, searchQuery } = state;
  const queryResults = searchResults;

  return (
    <SearchWrapper>
      <SearchForm onSubmit={handleSubmit}>
        <input
          id="Search"
          value={searchQuery}
          onChange={searchData}
          placeholder="Enter Your Search Topic"
        />
      </SearchForm>
      <SearchResult>
        {!queryResults.length && searchQuery !== "" && (
          <NoResult>No result found</NoResult>
        )}

        {queryResults.length !== 0 && (
          <Scrollbars
            autoHeight={true}
            autoHeightMax={505}
            className="search-scrollbar"
          >
            {queryResults.map((item: any) => {
              // Random Placeholder Color
              const color = setPlaceholderColor();
              return (
                <PostList
                  key={item.full_slug}
                  title={item.name}
                  url={item.full_slug}
                  image={item.content.banner_image}
                  date={item.published_at}
                  tags={item.tag_list}
                  placeholderBG={color}
                />
              );
            })}
          </Scrollbars>
        )}
      </SearchResult>
    </SearchWrapper>
  );
}

export default Search;
